import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'

// Language
import { getLanguage } from 'services/language'

// Libraries
import { map as _map } from 'lodash'

// Components
import { ButtonPhone } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import LanguageSwitch from 'components/LanguageSwitch'

// Images
import logo from 'img/logo.svg'

// CSS
import './Header.scss'

const Header = () => {
  const language = getLanguage()

  const { contactPageNL, contactPageEN, headerNL, headerEN } =
    useStaticQuery(graphql`
      {
        contactPageNL: wordpressPage(wordpress_id: { eq: 17 }) {
          acf {
            phonenumber
          }
        }

        contactPageEN: wordpressPage(wordpress_id: { eq: 72 }) {
          acf {
            phonenumber
          }
        }

        headerNL: wordpressWpComponenten(
          slug: { eq: "header" }
          wpml_current_locale: { eq: "nl_NL" }
        ) {
          ...headerContent
        }

        headerEN: wordpressWpComponenten(
          slug: { eq: "header" }
          wpml_current_locale: { eq: "en_US" }
        ) {
          ...headerContent
        }
      }

      fragment headerContent on wordpress__wp_componenten {
        acf {
          headerlinks {
            item {
              title
              url
            }
            submenu {
              submenuitem {
                title
                url
              }
            }
          }
        }
      }
    `)

  const [isCollapsed, setCollapsed] = useState(true)

  const headerContent = language === 'nl_NL' ? headerNL : headerEN
  const contactContent = language === 'nl_NL' ? contactPageNL : contactPageEN

  return (
    <nav className="header">
      <div className="navbar-container container p-0 position-relative">
        <div className="navbar-container-middle">
          <div className="navbar-brand">
            <Link
              to={language === 'nl_NL' ? '/' : '/en/home/'}
              className="navbar-item"
            >
              <img src={logo} alt="" />
              Home
            </Link>
          </div>

          <button
            type="button"
            onClick={() => setCollapsed(!isCollapsed)}
            className={`navbar-collapse-button${
              isCollapsed ? ' navbar-collapse-button-collapsed' : ''
            } text-center d-block d-lg-none`}
          >
            {isCollapsed ? (
              <>
                <span className="navbar-collapse-button-stripe d-block" />
                <span className="navbar-collapse-button-stripe d-block" />
                <span className="navbar-collapse-button-stripe d-block" />
              </>
            ) : (
              <div className="navbar-collapse-button-close" />
            )}
            menu
          </button>

          <HeaderMenuMobile
            menuItems={headerContent.acf.headerlinks}
            isCollapsed={isCollapsed}
            setCollapse={setCollapsed}
          />
          <HeaderMenuDesktop menuItems={headerContent.acf.headerlinks} />
        </div>
        <div className="header-phone d-none d-sm-block">
          <ButtonPhone>
            <a href={`tel:${contactContent.acf.phonenumber}`}>
              {contactContent.acf.phonenumber}
            </a>
          </ButtonPhone>
        </div>
        <div className="navbar-container-right d-none d-lg-block" />
        <div className="navbar-container-right-overflow d-none d-lg-block" />
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ isCollapsed, menuItems }) => (
  <CSSTransition
    in={!isCollapsed}
    timeout={300}
    unmountOnExit
    classNames="header-menu"
  >
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems menuItems={menuItems} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ menuItems }) => (
  <ul className="navbar-items d-none d-lg-flex h-100">
    <HeaderMenuItems menuItems={menuItems} />
  </ul>
)

const HeaderMenuItems = ({ menuItems }) => {
  return (
    <>
      {menuItems.map(({ item, submenu }) => (
        <motion.li
          initial="rest"
          whileHover="hover"
          animate="rest"
          key={item.url}
          className="position-relative h-100 d-lg-flex align-items-center"
        >
          <CustomLink to={item.url}>{item.title}</CustomLink>
          {submenu && submenu.length > 0 && (
            <motion.ul
              className="submenu overflow-hidden"
              initial={{ height: 0 }}
              animate={{ height: '505px', width: '365px' }}
              exit={{ height: 0 }}
              variants={{
                rest: {
                  opacity: 0,
                  y: -10,
                  display: 'none',
                },
                hover: {
                  opacity: 1,
                  y: 0,
                  display: 'flex',
                },
              }}
            >
              <div>
                {submenu.map(({ submenuitem }) => (
                  <li key={submenuitem.url}>
                    <CustomLink to={submenuitem.url}>
                      {parse(submenuitem.title)}
                    </CustomLink>
                  </li>
                ))}
              </div>
            </motion.ul>
          )}
        </motion.li>
      ))}
      <li className="d-none d-lg-inline-block">
        <div className="header-seperator" />
      </li>
      <li>
        <LanguageSwitch />
      </li>
    </>
  )
}

export default Header
