import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

import 'styles/templates/PageTemplate.scss'
import { ButtonDefault } from '../components/Elements/Elements'
import ParseContent from '../components/ParseContent'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      excerpt
      content
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        packages {
          title
          kosten
          opsommingen
          link {
            title
            url
            target
          }
        }
      }
      yoast_title
      yoast_meta {
        name
        content {
          localFile {
            publicURL
          }
        }
        property
      }
    }
  }
`

const Page = ({
  data: {
    page: {
      title,
      excerpt,
      path,
      content,
      acf: fields,
      yoast_title,
      yoast_meta,
    },
  },
}) => {
  return (
    <Layout backgroundImage={fields.backgroundImage}>
      {fields.backgroundImage && fields.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          title={yoast_title}
          description={excerpt}
          image={fields.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO
          yoast={{ meta: yoast_meta }}
          title={yoast_title}
          description={excerpt}
          pathname={path}
        />
      )}
      <div className="page-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleDefault asH1 className="pl-lg-5">
                {title}
              </TitleDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="container pb-5">
          <BoxDefault>
            <ContentDefault className="py-4 px-3 px-lg-5">
              <ParseContent content={content} />
            </ContentDefault>
          </BoxDefault>
        </div>

        {fields.packages && fields.packages !== undefined && (
          <section id="pakketten">
            <div className="container-xl">
              <div className="row justify-content-center py-5">
                {fields.packages.map((item, index) => (
                  <div className="col-md-6 col-lg-4 packages mb-4" key={index}>
                    <div className="inner">
                      <h3 className="title">{item.title}</h3>
                      <p className="price">{item.kosten}</p>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.opsommingen }}
                      />
                      <div className="cta">
                        <ButtonDefault to={item.link.url} target="_blank">
                          {item.link.title}
                        </ButtonDefault>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

export default Page
