import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Libraries
import {
  size as _size,
  map as _map,
  each as _each,
  filter as _filter,
} from 'lodash'

// Components
import { TitleDefault, LineAlt, ContentDefault } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import Socials from 'components/Socials'

// Language
import { getLanguage } from 'services/language'

// CSS
import './Footer.scss'

// Images
import logo from 'img/logo.svg'

// Icon
import pin from 'img/icon/pin-orange.svg'
import telephone from 'img/icon/telephone-orange.svg'
import envelope from 'img/icon/black-back-closed-envelope-shape-orange.svg'
import whatsapp from 'img/icon/whatsapp.svg'

const Footer = () => {
  const language = getLanguage()

  const {
    contactPageNL,
    contactPageEN,
    homePageNL,
    homePageEN,
    footerNL,
    footerEN,
    servicesNL,
    servicesEN,
  } = useStaticQuery(graphql`
    {
      contactPageNL: wordpressPage(wordpress_id: { eq: 17 }) {
        acf {
          phonenumber
          mobilephone {
            title
            url
          }
          streetname
          housenumber
          zipcode
          city
          country
          emailAddress: email_address
        }
      }

      contactPageEN: wordpressPage(wordpress_id: { eq: 72 }) {
        acf {
          phonenumber
          mobilephone {
            title
            url
          }
          streetname
          housenumber
          zipcode
          city
          country
          emailAddress: email_address
        }
      }

      homePageNL: wordpressPage(wordpress_id: { eq: 2 }) {
        fields: acf {
          title: banner_title
          subtitle: banner_subtitle
        }
      }

      homePageEN: wordpressPage(wordpress_id: { eq: 52 }) {
        fields: acf {
          title: banner_title
          subtitle: banner_subtitle
        }
      }

      servicesNL: allWordpressPage(
        sort: { fields: acf___sort_number, order: ASC }
        filter: {
          parent_element: { slug: { in: ["diensten", "services"] } }
          wpml_current_locale: { eq: "nl_NL" }
        }
      ) {
        edges {
          node {
            ...servicesContent
          }
        }
      }

      servicesEN: allWordpressPage(
        sort: { fields: acf___sort_number, order: ASC }
        filter: {
          parent_element: { slug: { in: ["diensten", "services"] } }
          wpml_current_locale: { eq: "en_US" }
        }
      ) {
        edges {
          node {
            ...servicesContent
          }
        }
      }

      footerNL: wordpressWpComponenten(
        slug: { eq: "footer" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...footerContent
      }

      footerEN: wordpressWpComponenten(
        slug: { eq: "footer" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...footerContent
      }
    }

    fragment footerContent on wordpress__wp_componenten {
      acf {
        links {
          title
          path
        }
        vatText: vat_text

        termsAndConditionTitle: terms_and_condition_title
        termsAndCondition: terms_and_condition {
          url {
            localFile {
              publicURL
            }
          }
        }

        privacyPolicyTitle: privacy_policy_title
        privacyPolicyLocation: privacy_policy_location
      }
    }
  `)

  const footerContent = language === 'nl_NL' ? footerNL : footerEN
  const links = _filter(footerContent.acf.links, ({ title }) => title !== null)
  const breakPoint = Math.floor(_size(links) / 2 + 2)
  const groupedMenuItems = {}
  let groupedIndex = 0

  _each(links, (link, index) => {
    if (index + 1 === breakPoint) groupedIndex++

    if (!groupedMenuItems[groupedIndex]) groupedMenuItems[groupedIndex] = []

    groupedMenuItems[groupedIndex].push(link)
  })

  const servicesContent = language === 'nl_NL' ? servicesNL : servicesEN
  const homePageContent = language === 'nl_NL' ? homePageNL : homePageEN
  const contactContent = language === 'nl_NL' ? contactPageNL : contactPageEN

  return (
    <div className="footer position-relative mt-5 color-background-main">
      <div className="footer-big-circle" />
      <div className="container position-relative py-5">
        <div className="footer-top">
          <div className="d-inline-block pl-3 pr-4 pr-md-0">
            <img className="footer-top-logo" src={logo} alt="" />
          </div>
          <div className="d-none d-md-inline-block pl-5 pr-4">
            {homePageContent.fields.title} {homePageContent.fields.subtitle}
          </div>
        </div>
        <div className="footer-top-right" />
        <div className="footer-top-right-overflow" />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <TitleDefault>Menu</TitleDefault>
            <LineAlt className="mt-4" />
            <ContentDefault>
              <div className="row mt-3">
                {_map(groupedMenuItems, (menuItems, index) => (
                  <div key={index} className="col">
                    <ul>
                      {_map(menuItems, ({ title, path }) => (
                        <li key={path}>
                          <CustomLink className="ignore-element" to={path}>
                            {title}
                          </CustomLink>
                        </li>
                      ))}
                    </ul>
                    {parseInt(index) === 1 && <Socials />}
                  </div>
                ))}
              </div>
            </ContentDefault>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-0">
            <TitleDefault>PBXcomplete</TitleDefault>
            <LineAlt className="mt-4" />
            <ContentDefault>
              <ul className="mt-3 text-left">
                {_map(
                  servicesContent.edges,
                  ({ node: { wordpress_id: id, path, title } }) => (
                    <li key={id}>
                      <CustomLink className="ignore-element" to={path}>
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                      </CustomLink>
                    </li>
                  )
                )}
              </ul>
            </ContentDefault>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-0">
            <TitleDefault>Contact</TitleDefault>
            <LineAlt className="mt-4" />
            <ContentDefault>
              <div className="footer-info text-left row mt-3 pl-3">
                <div className="col-1 p-0">
                  <img src={pin} alt="" />
                </div>
                <div className="col-11">
                  {contactContent.acf.streetname}{' '}
                  {contactContent.acf.housenumber}
                  <br />
                  {contactContent.acf.zipcode} {contactContent.acf.city}
                  <br />
                  {contactContent.acf.country}
                </div>

                <div className="col-1 p-0 mt-3 mt-lg-0">
                  <img src={telephone} alt="" />
                </div>
                <div className="col-11 mt-3 mt-lg-0">
                  <a
                    href={`tel:${contactContent.acf.phonenumber}`}
                    className="ignore-element"
                  >
                    {contactContent.acf.phonenumber}
                  </a>
                </div>

                <div className="col-1 p-0 mt-3 mt-lg-0">
                  <img src={envelope} alt="" />
                </div>
                <div className="col-11 mt-3 mt-lg-0">
                  <a
                    href={`mailto:${contactContent.acf.emailAddress}`}
                    className="ignore-element"
                  >
                    {contactContent.acf.emailAddress}
                  </a>
                </div>

                <div className="col-1 p-0 mt-3 mt-lg-0">
                  <img src={whatsapp} alt="" />
                </div>
                <div className="col-11 mt-3 mt-lg-0">
                  <a
                    href={contactContent.acf.mobilephone.url}
                    className="ignore-element"
                    target="_blank"
                  >
                    {contactContent.acf.mobilephone.title}
                  </a>
                </div>
              </div>
            </ContentDefault>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-2 color-text-light font-size-s font-weight-s color-background-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <a
                href={
                  footerContent.acf.termsAndCondition.url.localFile.publicURL
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                {footerContent.acf.termsAndConditionTitle}
              </a>{' '}
              -{' '}
              <CustomLink to={footerContent.acf.privacyPolicyLocation}>
                {footerContent.acf.privacyPolicyTitle}
              </CustomLink>
            </div>
            <div className="col-md-6 text-right">
              * {footerContent.acf.vatText}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
