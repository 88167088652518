/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'

import 'styles/ParseContent.scss'
import { ButtonDefault } from './Elements/Elements'

const ParseContent = ({ content }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  return parse(content, {
    replace: (domNode) => {
      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        attributes.src = `${sourceUrl}${domNode.attribs.src}`
        attributes.className = attributes.class

        delete attributes.class
        delete attributes.srcset

        if (attributes.style) {
          delete attributes.style
        }

        return <img {...attributes} />
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (domNode.children[0].name === 'img') {
          const imgattributes = domNode.children[0].attribs

          imgattributes.src = `${sourceUrl}${domNode.children[0].attribs.src}`
          imgattributes.className = attributes.class

          delete imgattributes.class
          delete imgattributes.srcset

          if (attributes.style) {
            delete attributes.style
          }

          return (
            <a {...attributes}>
              <img {...imgattributes} />
            </a>
          )
        }

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        if (attributes.class === 'button-default') {
          return (
            <ButtonDefault to={attributes.href} target={attributes.target}>
              {domToReact(domNode.children, {})}
            </ButtonDefault>
          )
        }

        return (
          <Link to={attributes.href}>{domToReact(domNode.children, {})}</Link>
        )
      }
    },
  })
}

export default ParseContent
