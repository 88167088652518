import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import {
  TitleDefault,
  TitleAlt,
  ButtonDefault,
  BannerDefault,
  ContentDefault,
  LineDefault,
} from 'components/Elements'
import Services from 'components/Services'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Whitepaper from 'components/Whitepaper'
import WhyUs from 'components/WhyUs'
import Partnering from 'components/Partnering'
import Testimonial from 'components/Testimonial'

// Language
import { getLanguage } from 'services/language'

// CSS
import 'styles/templates/HomeTemplate.scss'

export const pageQuery = graphql`
  query ($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      excerpt
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        banner_title
        banner_subtitle
        banner_text
        banner_button_text
        banner_button_location
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 650) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoast_title
      yoast_meta {
        name
        content {
          localFile {
            publicURL
          }
        }
        property
      }
    }

    servicesTextNL: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "nl_NL" }
    ) {
      ...servicesTextContent
    }

    servicesTextEN: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "en_US" }
    ) {
      ...servicesTextContent
    }
  }

  fragment servicesTextContent on wordpress__wp_componenten {
    acf {
      textTop: text_top
      textBottom: text_bottom
      textButton: text_button
    }
  }
`

function HomePage({ data: { page, servicesTextNL, servicesTextEN } }) {
  const language = getLanguage()
  const servicesTextContent =
    language === 'nl_NL' ? servicesTextNL : servicesTextEN

  return (
    <Layout backgroundImage={page.acf.backgroundImage}>
      {page.acf.backgroundImage && page.acf.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: page.yoast_meta }}
          pathname={page.path}
          title={page.yoast_title}
          description={page.acf.banner_text}
          image={page.acf.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="home-template">
        <BannerDefault className="pb-5 pt-lg-5">
          <div className="row pt-3">
            <div className="col-12 col-lg-5 position-relative mx-auto d-lg-none">
              <Img
                className="home-template-banner-image position-lg-absolute mx-auto"
                fluid={page.acf.banner_image.localFile.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-lg-7 py-4 mt-5 mt-lg-0">
              <TitleDefault asH1>{page.acf.banner_title}</TitleDefault>
              <TitleAlt className="pl-lg-5">
                {page.acf.banner_subtitle}
              </TitleAlt>
              <div className="pr-lg-5 mr-lg-5">
                <div className="pr-lg-5 mr-lg-5">
                  <ContentDefault className="mt-4">
                    <div
                      dangerouslySetInnerHTML={{ __html: page.acf.banner_text }}
                    />
                  </ContentDefault>
                  <div className="mt-5 text-right">
                    <ButtonDefault to={page.acf.banner_button_location}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: page.acf.banner_button_text,
                        }}
                      />
                    </ButtonDefault>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 position-relative d-none d-lg-flex">
              <Img
                className="position-lg-absolute home-template-banner-image"
                fluid={page.acf.banner_image.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </BannerDefault>

        <div className="mt-5 container">
          <LineDefault />
          <TitleAlt className="mt-3">
            {servicesTextContent.acf.textTop}
          </TitleAlt>
          <TitleDefault className="pl-lg-5">
            {servicesTextContent.acf.textBottom}
          </TitleDefault>
          <Services />
        </div>

        <div className="mt-5">
          <Whitepaper />
        </div>

        <div>
          <WhyUs />
        </div>

        <div className="mt-5">
          <Testimonial />
        </div>

        <div className="container mt-5 py-5">
          <Partnering />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
