import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

// Language
import { getLanguage } from 'services/language'
import parse from 'html-react-parser'
// Libraries
import { find as _find, size as _size } from 'lodash'

// Third Party
import { Helmet } from 'react-helmet'

import favicon from '../img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 200, quality: 100) {
          src
        }
      }
    }
  }
`

function SEO({
  title,
  description,
  image,
  pathname,
  article,
  keywords,
  yoast,
}) {
  const language = getLanguage()

  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultKeywords,
            defaultTitle,
            defaultDescription,
            siteUrl,
          },
        },
        file: featuredImage,
      }) => {
        if (yoast) {
          const yoastTitleObj = _find(
            yoast.meta,
            ({ property }) => property === 'og:title'
          )
          const yoastDescriptionObj = _find(
            yoast.meta,
            ({ name }) => name === 'description'
          )

          const yoastTitle =
            _size(yoastTitleObj) > 0 && yoastTitleObj.content
              ? yoastTitleObj.content
              : defaultTitle
          const yoastDescription =
            _size(yoastDescriptionObj) > 0 && yoastDescriptionObj.content
              ? yoastDescriptionObj.content
              : defaultDescription

          title = title || yoastTitle
          description = description || yoastDescription
        }

        const isImageMeta = yoast.meta.find(
          ({ name }) => name === 'description'
        )

        // check if isImageMeta.content is not null
        if (isImageMeta && isImageMeta.content) {
          description = isImageMeta.content
        }

        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: image
            ? `${siteUrl}${image}`
            : `${siteUrl}${featuredImage.childImageSharp.resize.src}`,
          url: `${siteUrl}${pathname || '/'}`,
          keywords: keywords || defaultKeywords,
        }

        return (
          <Helmet title={seo.title.substr(0, 60)}>
            <html lang="nl" />
            <link rel="icon" href={favicon} />
            <meta name="description" content={seo.description.substr(0, 150)} />
            <meta name="keywords" content={seo.keywords} />
            <meta name="image" content={seo.image} />
            <meta name="og:site_name" content={defaultTitle} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && (
              <meta property="og:image" itemProp="image" content={seo.image} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {language === 'nl_NL'
              ? [
                  <meta key={0} property="og:locale" content="nl_NL" />,
                  <meta
                    key={1}
                    property="og:locale:alternate"
                    content="en_GB"
                  />,
                ]
              : [
                  <meta key={0} property="og:locale" content="en_GB" />,
                  <meta
                    key={1}
                    property="og:locale:alternate"
                    content="nl_NL"
                  />,
                ]}
            <script
              defer
              src="//code.tidio.co/ihhasqbeiovnab3qumjeuyq1l5iapsns.js"
            />
            <meta
              name="google-site-verification"
              content="u-Kl7EaXQKlCheg3j-ajbTup5A4L5LttIyycMS3f1aU"
            />

            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="4ffc73a0-49dc-4d81-add3-63ef8f723a27"
              type="text/javascript"
              async
            />
          </Helmet>
        )
      }}
    />
  )
}

export default SEO
