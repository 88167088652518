import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import {
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  ContentDefault,
  BoxDefault,
  LineDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CallToAction from 'components/CallToAction'
import Services from 'components/Services'
import Packages from 'components/Packages'
import Searchtable from 'components/Searchtable'
import ParseContent from 'components/ParseContent'

// Language
import { getLanguage } from 'services/language'

// CSS
import 'styles/templates/ServiceTemplate.scss'
import { ButtonDefault } from '../components/Elements/Elements'

export const pageQuery = graphql`
  query ($wordpress_id: Int!) {
    service: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        icon {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 60) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        textTitle: text_title
        textSubtitle: text_subtitle
        textTop: text_top
        textBottom: text_bottom

        callToActionTitle: call_to_action_title
        callToActionSubtitle: call_to_action_subtitle
        callToActionButtonText: call_to_action_button_text
        callToActionButtonLocation: call_to_action_button_location

        searchtableId: searchtable_id

        packagesTitle: packages_title
        packagesSubtitle: packages_subtitle
        packagesButtonText: packages_button_text
        packageItems: package_repeater {
          location: package_button_location
          image: package_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_title
      yoast_meta {
        name
        content {
          localFile {
            publicURL
          }
        }
        property
      }
    }

    servicesPackages: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        packages {
          title
          kosten
          opsommingen
          link {
            title
            url
            target
          }
        }
      }
    }

    servicesTextNL: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "nl_NL" }
    ) {
      ...servicesTextContent
    }

    servicesTextEN: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "en_US" }
    ) {
      ...servicesTextContent
    }
  }
`

function Service({
  data: {
    service: { title, path, content, yoast_title, acf: fields, yoast_meta },
    servicesPackages,
    servicesTextNL,
    servicesTextEN,
  },
}) {
  const language = getLanguage()
  const servicesTextContent =
    language === 'nl_NL' ? servicesTextNL : servicesTextEN

  return (
    <Layout backgroundImage={fields.backgroundImage}>
      {fields.backgroundImage && fields.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          title={yoast_title}
          description={content}
          image={fields.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO
          yoast={{ meta: yoast_meta }}
          title={yoast_title}
          description={content}
          pathname={path}
        />
      )}
      <div className="service-template">
        <div className="service-template-content container pt-5">
          <TitleAlt className="mt-3">
            <div dangerouslySetInnerHTML={{ __html: fields.textTitle }} />
          </TitleAlt>
          <TitleDefault asH1 className="pl-lg-5">
            <div dangerouslySetInnerHTML={{ __html: fields.textSubtitle }} />
          </TitleDefault>
          <BoxDefault className="mt-4">
            <div className="py-4 px-3 px-lg-5">
              <div className="service-template-content-header position-relative d-flex align-items-center">
                <Img
                  className={`service-template-content-header-icon ${
                    title === '3CX Free Plus en 3CX SMB Plus' ? 'bigger' : ''
                  }`}
                  fluid={fields.icon.localFile.childImageSharp.fluid}
                />
                <TitleSubDefault className="service-template-content-header-title ml-4">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </TitleSubDefault>
              </div>
              <ContentDefault className="mt-3">
                <ParseContent content={fields.textTop} />
              </ContentDefault>
            </div>
          </BoxDefault>
        </div>

        {fields.callToActionTitle && fields.callToActionButtonText && (
          <div className="mt-5">
            <CallToAction
              title={fields.callToActionTitle}
              subtitle={fields.callToActionSubtitle}
              buttonText={fields.callToActionButtonText}
              buttonLocation={fields.callToActionButtonLocation}
            />
          </div>
        )}

        {fields.packagesTitle && fields.packagesSubtitle && (
          <div className="mt-5 mb-4 pt-5">
            <Packages
              title={fields.packagesTitle}
              subtitle={fields.packagesSubtitle}
              buttonText={fields.packagesButtonText}
              items={fields.packageItems}
            />
          </div>
        )}

        {fields.textBottom && (
          <div className="service-template-content container pt-5">
            <BoxDefault className="mt-4">
              <div className="py-4 px-3 px-lg-5">
                <ContentDefault className="mt-3">
                  <ParseContent content={fields.textBottom} />
                </ContentDefault>
              </div>
            </BoxDefault>
          </div>
        )}

        {fields.searchtableId !== null && (
          <div className="service-template-content container pt-5">
            <BoxDefault className="mt-4">
              <div className="py-4 px-3 px-lg-5">
                <div className="mt-3">
                  <Searchtable id={parseInt(fields.searchtableId)} />
                </div>
              </div>
            </BoxDefault>
          </div>
        )}

        {servicesPackages &&
          servicesPackages.acf &&
          servicesPackages.acf.packages &&
          servicesPackages.acf.packages.length > 0 && (
            <section id="pakketten">
              <div className="container-xl">
                <div className="row py-5 justify-content-center">
                  {servicesPackages.acf.packages.map((item, index) => (
                    <div
                      className="col-md-6 col-lg-4 packages mb-4"
                      key={index}
                    >
                      <div className="inner">
                        <h3 className="title">{item.title}</h3>
                        <p className="price">{item.kosten}</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.opsommingen }}
                        />
                        <div className="cta">
                          {item.link && item.link.url && (
                            <ButtonDefault to={item.link.url} target="_blank">
                              {item.link.title}
                            </ButtonDefault>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}

        <div className="mt-5 py-5">
          <div className="container pb-5">
            <LineDefault />
            <TitleAlt className="mt-3">
              {servicesTextContent.acf.textTop}
            </TitleAlt>
            <TitleDefault className="pl-lg-5">
              {servicesTextContent.acf.textBottom}
            </TitleDefault>
            <Services />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Service
