/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Language
import { getLanguage } from 'services/language'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BoxDefault,
  LineDefault,
  TitleAlt,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CallToAction from 'components/CallToAction'
import Services from 'components/Services'
import ParseContent from 'components/ParseContent'

import 'styles/templates/PageTemplate.scss'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        callToActionTitle: call_to_action_title
        callToActionSubtitle: call_to_action_subtitle
        callToActionButtonText: call_to_action_button_text
        callToActionButtonLocation: call_to_action_button_location

        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content {
          localFile {
            publicURL
          }
        }
        property
      }
    }

    servicesTextNL: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "nl_NL" }
    ) {
      ...servicesTextContent
    }

    servicesTextEN: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "en_US" }
    ) {
      ...servicesTextContent
    }
  }
`

const Page = ({
  data: {
    page: { title, path, content, acf: fields, yoastMeta },
    servicesTextNL,
    servicesTextEN,
  },
}) => {
  const language = getLanguage()
  const servicesTextContent =
    language === 'nl_NL' ? servicesTextNL : servicesTextEN

  return (
    <Layout backgroundImage={fields.backgroundImage}>
      {fields.backgroundImage && fields.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoastMeta }}
          pathname={path}
          description={content}
          image={fields.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      )}
      <div>
        <BannerDefault className="page-template py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleDefault asH1 className="pl-lg-5">
                {title}
              </TitleDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="page-template">
          <div className="container pb-5">
            <BoxDefault>
              <ContentDefault className="py-4 px-3 px-lg-5">
                <ParseContent content={content} />
              </ContentDefault>
            </BoxDefault>
          </div>
        </div>

        <div className="my-5 pb-5">
          <CallToAction
            title={fields.callToActionTitle}
            subtitle={fields.callToActionSubtitle}
            buttonText={fields.callToActionButtonText}
            buttonLocation={fields.callToActionButtonLocation}
          />
        </div>

        <div className="mt-5 py-5">
          <div className="container pb-5">
            <LineDefault />
            <TitleAlt className="mt-3">
              {servicesTextContent.acf.textTop}
            </TitleAlt>
            <TitleDefault className="pl-lg-5">
              {servicesTextContent.acf.textBottom}
            </TitleDefault>
            <Services />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
