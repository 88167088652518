/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import CookieConsent from 'react-cookie-consent'

// Components
import { getLanguage } from 'services/language'
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children, backgroundImage }) => {
  const language = getLanguage()

  return (
    <>
      <div className="body-small-circle d-none d-xl-block" />
      <div className="body-medium-circle d-none d-lg-block" />
      {backgroundImage && backgroundImage.localFile ? (
        <BackgroundImage
          className="body-big-circle"
          fluid={backgroundImage.localFile.childImageSharp.fluid}
        >
          <div className="body-big-circle-inner" />
        </BackgroundImage>
      ) : (
        <div className="body-big-circle">
          <div className="body-big-circle-inner" />
        </div>
      )}
      <Header />
      <>{children}</>
      <Footer />
    </>
  )
}

export default Layout
