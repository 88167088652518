import React from 'react'
import { graphql } from 'gatsby'

import Home from '../templates/home'

const HomePage = ({ data }) => {
  return <Home data={data} />
}

export default HomePage

export const pageQuery = graphql`
  query {
    page: wordpressPage(wordpress_id: { eq: 2 }) {
      path
      excerpt
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        banner_title
        banner_subtitle
        banner_text
        banner_button_text
        banner_button_location
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 650) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoast_title
      yoast_meta {
        name
        content {
          localFile {
            publicURL
          }
        }
        property
      }
    }

    servicesTextNL: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "nl_NL" }
    ) {
      ...servicesTextContent
    }

    servicesTextEN: wordpressWpComponenten(
      slug: { eq: "diensten-text" }
      wpml_current_locale: { eq: "en_US" }
    ) {
      ...servicesTextContent
    }
  }

  fragment servicesTextContent on wordpress__wp_componenten {
    acf {
      textTop: text_top
      textBottom: text_bottom
      textButton: text_button
    }
  }
`
