import React from 'react'
import CustomLink from 'components/CustomLink'

// Images
import rightArrowOrange from 'img/icon/right-arrow-orange.svg'
import rightArrowWhite from 'img/icon/right-arrow-white.svg'
import chevronArrowDownOrange from 'img/icon/chevron-arrow-down-orange.svg'
import telephone from 'img/icon/telephone.svg'

// CSS
import './Elements.scss'

export const ButtonDefault = ({
  isAnchor,
  to,
  children,
  className,
  target,
}) => {
  const classes = `button button-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell
      to={to}
      isAnchor={isAnchor}
      className={classes}
      target={target}
    >
      {children}
      <div className="button-default-icon">
        <img src={rightArrowOrange} alt="" />
      </div>
    </ButtonShell>
  )
}

export const ButtonSecondary = ({ isAnchor, to, children, className }) => {
  const classes = `button button-secondary${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
      <div className="button-secondary-icon">
        <img src={rightArrowOrange} alt="" />
      </div>
    </ButtonShell>
  )
}

export const ButtonAltDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-alt-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
      <div className="button-alt-default-icon">
        <img src={rightArrowOrange} alt="" />
      </div>
    </ButtonShell>
  )
}

export const ButtonAltSecondary = ({ isAnchor, to, children, className }) => {
  const classes = `button button-alt-secondary${
    className ? ` ${className}` : ``
  }`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
      <div className="button-alt-secondary-icon">
        <img src={rightArrowOrange} alt="" />
      </div>
    </ButtonShell>
  )
}

export const ButtonMore = ({ isAnchor, to, children, className }) => {
  const classes = `button button-more${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      <img src={chevronArrowDownOrange} alt="" />
      {children}
      <img src={chevronArrowDownOrange} alt="" />
    </ButtonShell>
  )
}

export const ButtonPhone = ({ isAnchor, to, children, className }) => {
  const classes = `button button-phone${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      <div className="button-phone-icon">
        <img src={telephone} alt="" />
      </div>
      {children}
    </ButtonShell>
  )
}

export const ButtonGoDefault = ({ isAnchor, to, className }) => {
  const classes = `button button-go button-go-default${
    className ? ` ${className}` : ``
  }`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      <img src={rightArrowWhite} alt="" />
      Gaan
    </ButtonShell>
  )
}

export const ButtonGoAlt = ({ isAnchor, to, className }) => {
  const classes = `button button-go button-go-alt${
    className ? ` ${className}` : ``
  }`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      <img src={rightArrowOrange} alt="" />
      Gaan
    </ButtonShell>
  )
}

const ButtonShell = ({ isAnchor, to, children, className, target }) =>
  !isAnchor ? (
    to ? (
      <CustomLink to={to} className={className}>
        {children}
      </CustomLink>
    ) : (
      <span className={className}>{children}</span>
    )
  ) : (
    <a href={to} className={className} target={target}>
      {children}
    </a>
  )

export const TitleDefault = ({ children, className, asH1 }) =>
  asH1 ? (
    <h1 className={`title title-default${className ? ` ${className}` : ``}`}>
      {children}
    </h1>
  ) : (
    <h2 className={`title title-default${className ? ` ${className}` : ``}`}>
      {children}
    </h2>
  )

export const TitleAlt = ({ children, className }) => (
  <h2 className={`title title-alt${className ? ` ${className}` : ``}`}>
    {children}
  </h2>
)

export const TitleSubDefault = ({ children, className }) => (
  <h4 className={`title title-sub-default${className ? ` ${className}` : ``}`}>
    {children}
  </h4>
)

export const BoxDefault = ({ children, className }) => (
  <div className={`box box-default${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const BoxFirst = ({ children, className }) => (
  <div className={`box box-first${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const BoxMiddle = ({ children, className }) => (
  <div className={`box box-middle${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const BoxLast = ({ children, className }) => (
  <div className={`box box-last${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const LineDefault = ({ className }) => (
  <div className={`line line-default${className ? ` ${className}` : ``}`} />
)

export const LineAlt = ({ className }) => (
  <div className={`line line-alt${className ? ` ${className}` : ``}`} />
)

export const TableDefault = ({ children, className }) => (
  <>
    <table
      className={`table table-default${className ? ` ${className}` : ``}`}
      border="0"
      cellPadding="0"
      cellSpacing="0"
    >
      {children}
    </table>
  </>
)

export const ContentDefault = ({ children, className }) => (
  <div className={`content content-default${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const ContentAlt = ({ children, className }) => (
  <div className={`content content-alt${className ? ` ${className}` : ``}`}>
    {children}
  </div>
)

export const BannerDefault = ({ children, className }) => (
  <div className={`banner${className ? ` ${className}` : ``}`}>
    <div className="container">{children}</div>
  </div>
)
